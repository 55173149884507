@import '../../styles/scss/globals.scss';

.navbarTop {
  background: $white;
  padding-top: 1.55rem;
  padding-bottom: 1.55rem;
  position: relative;
  z-index: 1;
}
.navContainer {
  max-width: 92.5%;
}
.navbarPublicTop {
  @extend .navbarTop;
  z-index: 3;
}

.languageSwitchBtn {
  font-weight: 600;
  line-height: normal;
  font-size: 1rem;
}

.languageSwitchBtn:hover {
  background-color: transparent !important;
  color: black !important;
  opacity: 1 !important;
}

.publicSideNav {
  background: $white;
  border-right: 1px solid $lightGrey;
  padding: 0;
  @include min(992) {
    position: sticky;
    top: 0.25rem;
    left: 0;
    align-items: flex-start;
    min-height: 100vh;
  }
  .sideNavContainer {
    margin: 0 auto;
    padding: 0;
    width: 100%;
  }
  .sideNavWrap {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    writing-mode: tb;
    writing-mode: tb-rl;
    writing-mode: vertical-rl;
    writing-mode: sideways-rl;
    -webkit-writing-mode: vertical-lr;
    -moz-writing-mode: vertical-lr;
    -ms-writing-mode: vertical-lr;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    .singleSideNav {
      width: 100%;
      display: table;
      height: 40vh;
      min-height: 24.5rem;
      max-height: 50rem;
      text-align: center;
      margin: 0 auto;
      font-size: 0.875rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      &.scrollWrap {
        justify-content: flex-start;
        min-height: 14.5rem;
        //min-height: 28vh;
        height: auto;
      }
      .singleSideLi {
        display: flex;
        margin: 1rem 0;
        width: 100%;
      }
      .singleSideLink {
        writing-mode: vertical-rl;
        width: 100%;
        display: flex;
        align-items: center;
        margin: 0 auto;
        color: $primary;
        padding: 0.5rem 1rem;
        border-left: 2px solid transparent;
        &:hover {
          opacity: 0.5;
        }
        &.active {
          border-color: $secondary;
        }
      }
    }
  }
}


.sidebar {
  margin-bottom: 1rem;

  .sideLinks {
      z-index: 2;
      max-height: 80vh;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      overflow: hidden;
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
          display: none;
      }

      .linkWrapper {
          flex: 1;
          text-align: center;
          margin-bottom: 0;

          .singleLink {
              color: $primary;
              font-weight: 600;
              white-space: nowrap;
              @include transition(0.2s);

              border: 0;
              border-bottom: 3px solid transparent;
              width: 100%;
              display: block;
              padding: 1rem;

              &:hover {
                  color: $primary;
                  border-color: $primary;
                  opacity: 0.8;
              }

              &:before {
                  content: none !important;
              }

              &.active {
                  border-color: $secondary;
              }
          }
      }

  }

}


@include max(767) {
  .navbarTop {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
